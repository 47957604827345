import React from 'react';
import SimpleHeader from "../../../../module_components/Displays/Headers/SimpleHeader";
import {Container, Card, CardHeader, CardBody, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import CustomerInfoItem from "../../../../module_components/Items/CustomerInfoItem";
import FilesTable from "./FilesTable";
import ContractsTable from "../../../../module_components/Pages/Contracts/ContractsTable";
import OffersTable from "./OffersTable";
import ConsumptionPointsTable from "./ConsumptionPointsTable";
import {
    deleteConsumptionPoint,
    getConsumptionPointsForCustomer,
    getCustomer,
    getContractsForCustomer,
    getOffersForCustomer,
    uploadFilesForCustomer,
    redirect,
    getFilesForCustomer,
    deleteSavedOffers,
    deleteFilesFromCustomer,
    downloadFilesFromCustomer,
    dynamic_action_call,
    upload_file_new
} from "../../../../module_components/Index/index";
import connect from "react-redux/es/connect/connect";
import validateVar from "../../../../module_components/Functions/validation/validateVariable";
import BadgeQuadratic from "../../../../module_components/Displays/Badges/BadgeQuadratic";
import PrimaryButton from "../../../../module_components/Displays/Buttons/PrimaryButton";
import {showErrorMessage} from "../../../../module_components/Index/index";
import {Calculator, Contact, Contacts, Data} from "../../../../globalNavigation";
import DynamicText from "../../../../module_components/Displays/Basic/BasicsDynamic/dynamicText";

class CustomerPage extends React.Component {
    state = {
        active_tab: '1',
        customer: {},
        consumption_points: [],
        contracts: [],
        offers: [],
        files: [],
        is_loaded_customer: false,
        is_loaded_files: false,
        is_loaded_offers: false,
        is_loaded_contracts: false,
        is_loaded_consumption_points: false,
        is_loaded: false,
        file: null,
    };

    componentDidMount(){
        this.customer_type = "BUSINESS"
        let customer_id = this.props.match.params.id;
        if (validateVar(customer_id) && this.props.match.params.id > 0 ){ //add
            this.props.getConsumptionPoints(customer_id);
            this.props.getCustomer(customer_id);
            this.props.getContracts(customer_id);
            this.props.getOffersForCustomer(customer_id);
            this.props.getFilesForCustomer(
                {
                    customer_id: customer_id
                });
        } else{ // error
            alert("Fehler beim Laden der Daten");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {customer, consumption_points, contracts, offers, files} = this.props;
        const {is_loaded_customer} = this.state;

        if(validateVar(customer) && customer !== prevProps.customer && !is_loaded_customer){
            this.customer_type = customer.customer_type;
            this.setState({customer: customer, is_loaded_customer: true});
        }

        if((validateVar(customer) && validateVar(consumption_points) && consumption_points !== prevProps.consumption_points) ||
            (validateVar(customer) && validateVar(consumption_points) && customer !== prevProps.customer)){
            let consumption_points_array = [];
            for(let csp in consumption_points){
                if(consumption_points.hasOwnProperty(csp)){
                    let consumption_point = consumption_points[csp];

                    if(customer.street === consumption_point.street &&
                        customer.house_number === consumption_point.house_number &&
                        +customer.postal_code === +consumption_point.postal_code){
                        consumption_point.full_address = 'Entspricht der ' + (customer.customer_type === 'BUSINESS' ? 'Firmenaschrift' : 'Privatanschrift');
                    }

                    consumption_points_array.push(consumption_point)
                }
            }
            this.setState({consumption_points: consumption_points_array, is_loaded_consumption_points: true});
        }

        if(validateVar(contracts) && contracts !== prevProps.contracts){
            // todo get units and get requests with limit/ offset
            this.setState({contracts: contracts.contracts, is_loaded_contracts: true});
        }

        if(validateVar(offers) && offers !== prevProps.offers){
            this.setState({offers: offers, is_loaded_offers: true});
        }

        if(validateVar(files) && files !== prevProps.files){
            this.setState({files: files, is_loaded_files: true});
        }
    }


    uploadFile = (params, file) => {
        params.customer_id = this.props.match.params.id;
        this.props.uploadFilesForCustomer(params, file);
    };

    delete = (table, ids) => {
        if (table === "consumption_points"){
            this.props.deleteConsumptionPoint(ids);
        } else if (table === "offers"){
            this.props.deleteSavedOffers(ids);
        }else if (table === "files"){
            this.props.deleteFilesFromCustomer(ids);
        }
    };

    render() {
        const {active_tab, is_loaded_customer, is_loaded_consumption_points, is_loaded_offers, is_loaded_files} = this.state;
        const {customer, consumption_points, contracts, offers, files} = this.state;
        let color_badge = 'orange';
        let disable_calc_btn = true;
        if (is_loaded_consumption_points) if (consumption_points.length > 0) disable_calc_btn = false;
        if (is_loaded_customer) if (customer.lead_status_display === "Kunde") color_badge = 'green';

        return (<>
            <SimpleHeader name="Kontaktansicht" returnButton='Zurück zu Kontakte' prevPage={Contacts} />
            <Container className='mt--6' fluid>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h2 className='d-inline-block'>
                                    <DynamicText is_loaded={is_loaded_customer} property={customer.company_display} width={120}/>
                                </h2>
                                <span className='ml-3'>
                                    <BadgeQuadratic is_loaded={is_loaded_customer} classes='mr-2' text={customer.customer_type_display} color={'grey'}/>
                                    <BadgeQuadratic is_loaded={is_loaded_customer} text={customer.lead_status_display} color={color_badge}/>
                                </span>
                            </Col>
                            <Col className='text-right'>
                                <PrimaryButton
                                    disabled={disable_calc_btn}
                                    classes={"mr-2"}
                                    value={"Kalkulieren"}
                                    onClick={() => this.props.redirect(Calculator + Data + "/" + this.props.match.params.id)}
                                />
                                <div onClick={() => this.props.redirect(Contact+'/'+this.props.match.params.id)} className='settings-wheel-btn'><i className="fas fa-cog" /></div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <CustomerInfoItem is_loaded={is_loaded_customer} title={'Kontaktdaten'} property={customer.full_name} />
                            </Col>
                            <Col>
                                <CustomerInfoItem is_loaded={is_loaded_customer} title={'Telefon'} property={customer.phone} />
                            </Col>
                            <Col>
                                <CustomerInfoItem is_loaded={is_loaded_customer} title={'E-mail'} property={customer.email} />
                            </Col>
                            <Col>
                                <CustomerInfoItem is_loaded={is_loaded_customer} title={'Adresse'} property={customer.address+ ", " + customer.postal_code +" "+ customer.city} />
                            </Col>
                            <Col>
                                <CustomerInfoItem is_loaded={is_loaded_customer} title={'Bonität'} property={customer.solvency} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader className='p-0'>
                        <Nav className='customer-nav-tabs four-tabs round' tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: active_tab === '1' })}
                                    onClick={() => { this.setState({active_tab: '1'}) }}
                                >
                                    Lieferstellen
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: active_tab === '2' })}
                                    onClick={() => { this.setState({active_tab: '2'}) }}
                                >
                                    Angebote
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: active_tab === '3' })}
                                    onClick={() => { this.setState({active_tab: '3'}) }}
                                >
                                    Aufträge
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: active_tab === '4' })}
                                    onClick={() => { this.setState({active_tab: '4'}) }}
                                >
                                    Dateien
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <CardBody className='p-0'>
                        <TabContent activeTab={active_tab}>
                            <TabPane tabId="1">
                                <ConsumptionPointsTable
                                    is_loaded={is_loaded_consumption_points}
                                    delete={(type, ids) => this.delete(type, ids)}
                                    redirect={(url) => this.props.redirect(url)}
                                    consumptionPoints={consumption_points}
                                    key_field={"consumption_point_id"}
                                    customer_id={customer.id}
                                />
                            </TabPane>
                            <TabPane tabId="2">
                               <OffersTable
                                   is_loaded={is_loaded_offers}
                                   delete={(type, ids) => this.delete(type, ids)}
                                   redirect={(url) => this.props.redirect(url)}
                                   offers={offers}
                                   is_private={this.customer_type === "PRIVATE"}
                                   contracts={contracts}/>

                            </TabPane>
                            <TabPane tabId="3">
                                <ContractsTable
                                    no_header={true}
                                    no_title_row={true}
                                    // is_loaded={is_loaded_contracts}
                                    // redirect={(url) => this.props.redirect(url)}
                                    contracts={contracts}
                                    key_field={"contract_id"}
                                    contracts_prev_loaded
                                />
                            </TabPane>
                            <TabPane tabId="4">
                                <FilesTable
                                    is_loaded={is_loaded_files}
                                    downloadFiles={this.props.downloadFiles}
                                    delete={(type, ids) => this.delete(type, ids)}
                                    uploadFileForCustomer={(params, file) => this.uploadFile(params, file)}
                                    files={files}
                                    showErrorMessage={(params) => this.props.showErrorMessage(params)}
                                />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Container>
        </>)
    }
}
let mapStateToProps = function(state)   {
    return {
        customer: state.customer,
        consumption_points: state.consumption_point_list,
        contracts: state.contract_list,
        offers: state.offer,
        files: state.files,
    }
};

let mapDispatchToProps = {
    getCustomer: getCustomer,

    redirect: redirect,

    getOffersForCustomer: getOffersForCustomer,
    deleteSavedOffers: deleteSavedOffers,

    getContracts: getContractsForCustomer,

    getConsumptionPoints: getConsumptionPointsForCustomer,
    deleteConsumptionPoint: deleteConsumptionPoint,

    uploadFilesForCustomer: uploadFilesForCustomer,
    downloadFiles: downloadFilesFromCustomer,
    getFilesForCustomer: getFilesForCustomer,
    deleteFilesFromCustomer: deleteFilesFromCustomer,

    showErrorMessage: showErrorMessage,

    //FileUpload requests
    dynamic_action_call: dynamic_action_call,
    default_file_upload: upload_file_new,
};

let CustomerPageContainer = connect(mapStateToProps, mapDispatchToProps)(CustomerPage);

export default CustomerPageContainer;