export default {};

export const default_loading_animation_width = 21;
export const default_loading_animation_height = 21;


export const appURL = process.env.REACT_APP_DOMAIN_NAME;
export const apiURL = process.env.REACT_APP_API_URL;
export const apiUrlNew = process.env.REACT_APP_API_URL_NEW;

/* API Request */
export const apiDeBugBearer = process.env.REACT_APP_DEBUG_KEY;
export const apiBearer = process.env.REACT_APP_API_KEY;
export const apiBearerNew = process.env.REACT_APP_API_KEY_NEW;
export const apiContentType = "application/json";
export const apiParamState = "BY";
export const project_name = process.env.REACT_APP_PROJECT_NAME;
export const project_names = {ehub: 'EVU_PROJECT', enermakler: 'CONSULTANT_PROJECT', wattline: 'WATTLINE_PROJECT'};
export const default_logo_id = process.env.REACT_APP_DEFAULT_LOGO_ID;

export const apiUser = process.env.REACT_APP_API_USER;
export const apiUserAuthToken = process.env.REACT_APP_API_USER_AUTH_TOKEN;

export const inSignUrl = process.env.REACT_APP_INSIGN_URL;
export const inSignName = process.env.REACT_APP_INSIGN_NAME;
export const inSignPw = process.env.REACT_APP_INSIGN_PW;


// prediction parameters
export const apiParamUserID = "10";
export const apiParamPredictionBegin = "2020-01-01";
export const apiParamPredictionEnd = "2020-12-31";
export const apiParamRatioBaseValue = "0.35";
export const apiParamBaseAdjustmentImpact = "1";
export const apiParamPredictedConsumption = "871080";
export const apiParamSheetNumber = "0";
export const apiParamFile = "file.csv";

/* API Services */
export const apiElectricityProfileService = "ElectricityProfileService";
export const apiGasProfileService = "GasProfileService";
export const apiCalculationManagementService = "CalculationManagementService";
export const apiCalculationAnalyticsService = "AnalyticsService";
export const apiServicePrediction = "PredictionService";
export const apiServiceNetfee = "NetFeeService";
export const apiServiceLoadProfile = "LoadProfileService";
export const apiServiceContractGenerator = "ContractGenerator";
export const apiServicePfc = "PFCService";
export const apiServicePricing = "PricingService";
export const apiPlatformService = "PlatformService";
export const apiPartnerService = "PartnerService";
export const apiFileSystemService = "FileSystemService";
export const apiPricingService = "PricingService";

export const apiError = "ERR";
export const zipNotFound = "ZIP_ERR";
/* API Functions */

/* password */

export const apiFunctionPasswordForgotten = "password_forgotten";
export const apiFunctionResetPassword = "reset_password";

/* login */
export const apiFunctionVerifyPassword = "customer_verify_password";

/* GET AG */
// netfeeservice functions

/* tariff */
export const apiFunctionGetTariff = "get_tariff";
export const apiFunctionAddTariff = "create_tariff";
export const apiFunctionGetTariffList = "list_tariffs";
export const apiFunctionDeleteTariff = "delete_tariff";
export const apiFunctionModifyTariff = "modify_tariff";
export const apiFunctionUploadTariffFile = "upload_tariff_file";
export const apiFunctionTestTariff = "test_tariff";
export const apiFunctionCopyTariff = "copy_tariff";
export const apiFunctionCleanTrees = "clean_trees";

/* CALCULATION */
export const apiFunctionDownloadCalculation = "download_calculation";

/* ANALYTICS */
export const apiFunctionAnalyticsConsumptionPointLocation =
    "location_consumption_points";
export const apiFunctionAnalyticsAverageContracts = "average_contracts";
export const apiFunctionAnalyticsAverageTariffs = "average_tariffs";
export const apiFunctionAnalyticsRankingTariffs = "ranking_tariffs";

/* employee */
export const apiFunctionListEmployees = "list_employees";
export const apiFunctionGetEmployee = "get_employee";
export const apiFunctionCreateEmployee = "create_employee";
export const apiFunctionDeleteEmployee = "delete_employee";
export const apiFunctionUpdateEmployee = "update_employee";
export const apiFunctionActivateEmployee = "activate_employee";

/* TENDER */
export const apiFunctionTender365 = "tender365";

/* INTEGRATIONS */
export const apiFunctionGetConnectedInterations = "list_connected_integrations";
export const apiFunctionGetDisconnectedInterations = "list_integrations";
export const apiFunctionDisconnectConnectedInterations =
    "disconnect_integration";
export const apiFunctionConnectInterations = "connect_integration";

/* contract */
export const apiFunctionGetContract = "get_contract";
export const apiFunctionGetContractList = "list_by_supplier";
export const apiFunctionContractModify = "modify_contract";
export const apiFunctionContractExport = "export_contract";
export const apiFunctionUploadContractFile = "upload_contract_file";

/*
offer
 */
export const apiFunctionOfferList = "ranking_calculations";


/* hpfc */
export const apiFunctionGetPfc = "getPFC";
export const apiFunctionGetGlobalPfc = "get_globals";
export const apiFunctionGetPfcList = "list_pfc";
export const apiFunctionDeletePfc = "delete_pfc";
export const apiFunctionSavePfc = "savePFC";
export const apiFunctionUpdateProviderPfc = "update_provider_pfc";

/* file system */
export const apiFunctionReturnResource = "returnResource";

/* price strategy */
export const apiFunctionListAttributes = "list_attributes";
export const apiFunctionCreateTree = "create_tree";
export const apiFunctionModifyTree = "update_tree";
export const apiFunctionGetTree = "get_tree";
export const apiFunctionDeleteTree = "delete_tree";

/* supplier */
export const apiFunctionLoginSupplier = "login_supplier";
export const apiFunctiongetEmailById = "get_email_by_customer_id";
export const apiFunctiongetGetSupplier = "get_supplier";
export const apiFunctionModifySupplier = "modify_supplier";

//calculate
export const apiFunctionCalculateAllTariffs = "calculate_all_tariffs";
export const apiFunctionGetCustomer = "get_customer";
export const apiFunctionModifyCustomer = "modify_customer";
export const apiFunctionDeleteCustomer = "delete_customer";

//subscribe model
export const apiFunctionCreateSubscription = "create_subscription";

//contract
export const apiFunctionCreateContract = "create_contract";
export const apiFunctionGetContractsByCustomer = "get_contracts_by_customer";
export const apiFunctionModifyContract = "modify_contract";
export const apiFunctionGenerateContract = "generate_contract";
export const apiFunctionDisplayContract = "display_contract";
export const apiFunctionListContractTemplates = "list_templates";

//get_all_supplier for preProvider
export const apiFunctionGetAllSuppliers = "get_all_suppliers";

// loadProfile functios apiFunctionGetLoadProfileAnalysisData
export const apiFunctionGetPredictionInformation = "getPredictionInformation";
export const apiFunctionDownLoadLp = "getLoadProfile";

// electricityProfiles functions
export const apiFunctionGetProfiles = "getProfiles";
export const apiFunctionPredictStandardProfile = "predict_standard_profile";

// CONSULTANT
export const apiFunctionListConsultants = "list_consultant";

// consumptionPoint functions
export const apiFunctionCreateConsumptionPoint = "create_consumption_point";
export const apiFunctionOfferAddConsumptionPoint = "add_consumption_point";
export const apiFunctionDeleteConsumptionPoint = "delete_consumption_point";
export const apiFunctionModifyConsumptionPoint = "modify_consumption_point";
export const apiFunctionGetCalculatedTariffs = "get_calculated_tariffs";
export const apiFunctionGetCspointsByOffer = "get_consumption_points_of_offer";
export const apiFunctionGetCspointsByCustomer = "get_consumption_points_by_customer";

// prediction functions (lastgang(loadprofile) upload)
export const apiFunctionPredictLoadProfile = "predictLoadProfile";

export const apiFunctionActivateCustomer = "activate_customer";

// netfeeservice functions
export const apiFunctionGetStreets = "get_streets";
export const apiFunctionsGetCity = "get_cities";
export const apiFunctionCalculateNetFee = "calculate_net_fee";

/* OFFER, TOKEN AND VOUCHER */
export const apiFunctionGetOfferByToken = "get_offer_by_token";
export const apiFunctionCreateOffer = "create_offer";
export const apiFunctionModifyOffer = "modify_offer";
export const apiFunctionValidateVoucher = "validate_offer_access_key";
export const apiFunctionValidateIban = "validate_iban";


// HISTORY UPDATE
export const respond_to_history = "respond_to_history";
export const list_timeline = "list_timeline";

export const emptyValue = "-/-";

export const maxAttemts = 6;

//keys
export const reCapture = process.env.REACT_APP_RECAPTURE_KEY;
export const encriptKey = process.env.REACT_APP_ENCRYPT_KEY;

export const static_download_url = apiUrlNew + "static_file/";

//UserRoles
export const userRole = "user";
export const managerRole = "manager";
export const adminRole = "admin";
export const Admin = "/admin";
export const Manager = "/manager";
export const User = "/user";

export const consumption_point_object_types = [
    "consumption_point",
    "consumption_point_information",
    "invoice_address",
    "cancellation"
];

export const plattform_data_array = [
    "--primary-color",
    "--secondary-color",
    "--bright-font-color",
    "--error-color",
    "--dark-font-color",
    "--background-color",
    "name",
    "favicon_id",
    "logo_id"
];
export const contract_invalid_status_arr = ["cancelled", "revoked", "expired", "terminated", "customer_checkout"];
export const get_logo_from_company = [project_names.ehub].includes(project_name); //projects with plattform settings to include the request on page load
export const tariffAttributes = [
    1,
    2,
    3,
    8,
    9,
    10,
    11,
    12,
    16,
    17,
    19,
    21,
    22,
    23,
    24,
    25,
    26,
    32,
    33,
    34,
    35
    // 37//TODO abstrakter file upload update
];
export const socketAttribute = [25, 26];
export const socketAttributeSelect = [25];
export const globalAttributes = [13, 14, 15, 27, 28, 29, 30];
export const multiselectAttributes = [4, 5, 7, 14, 15, 19, 20, 21, 22];
export const dateAttributes = [11, 12];
export const postalCodeAttribute = 17;
export const basePricePerCspAttribute = 25;
export const solvencyAttribute = 27;

//object used for ex- & including project functions for defined agency IDs (string)
export const agencies = {
    rheingas: process.env.REACT_APP_RHEINGAS_AGENCY_ID
}

//translations and values for each variable depending on if its Business (contract_price / energy_price) or Private (contract_price)
export const tariff_information_vars = {
    PRIVATE: {
        is_private: true,
        price_title: "Arbeitspreis",
        spot: "Ø",
        sale_tax_type: "(Brutto)",
        other_sale_tax_type: "(Netto)",
        price_hint: "",
        show_netfee: false,
        tariff : {
            price_display: "contract_price_gross_display",
            price_net_display: "contract_price_net_display",
            socket_display: "socket_gross_display",
            socket_net_display: "socket_display",
            yearly_price: "yearly_price_gross",
            yearly_price_display: "yearly_price_gross_display",
            yearly_price_rate_display: "yearly_contract_price_gross_display",
            yearly_price_sum_display: "yearly_contract_price_gross_display",
            yearly_socket_gross: "yearly_socket_gross",
            yearly_socket_display: "yearly_socket_gross_display",
        }
    },
    BUSINESS: {
        contract_price: {
            is_private: false,
            price_title: "Arbeitspreis",
            spot: "Ø",
            sale_tax_type: "(Netto)",
            other_sale_tax_type: "(Netto)",
            price_hint: "*zzgl. gesetzl. MwSt.",
            show_netfee: false,
            tariff : {
                price_display: "contract_price_gross_display",
                price_net_display: "contract_price_net_display",
                socket_display: "socket_gross_display",
                socket_net_display: "socket_display",
                yearly_price: "yearly_contract_price_gross",
                yearly_socket_gross: "yearly_socket_gross",
                socket_gross: "socket_gross",
                yearly_price_display: "yearly_price_gross_display",
                yearly_price_rate_display: "yearly_contract_price_gross_display",
                yearly_price_sum_display: "yearly_contract_price_gross_display",
                yearly_socket_display: "yearly_socket_gross_display",
            }
        },
        energy_price: {
            is_private: false,
            price_title: "Energiepreis",
            spot: "Ø",
            sale_tax_type: "(Netto)",
            other_sale_tax_type: "(Brutto)",
            price_hint: "*zzgl. Netzentgelte, Umlagen, Steuern und Abgaben",
            show_netfee: true,
            tariff: {
                // default vars in top lvl tariff
                price_display: "price_rate_display", // default price
                socket_display: "socket_display", // default
                socket_net_display: "socket_display",
                yearly_price: "yearly_price",
                yearly_socket_gross: "yearly_socket_gross",
                yearly_price_display: "yearly_price_display",
                yearly_price_rate_display: "yearly_price_rate_display",
                yearly_price_sum_display: "yearly_contract_price_gross_display",
                yearly_socket_display: "yearly_socket_net_display",

                // price information in table
                t_price_display: "", // if table in price information is hidden -> price var
                t_show_price_display: "", // if table in price information is shown -> price var
            }
        }
    },
}



export const numeric_regex =  /^\d+$/;//regex for numeric string
export const numeric_and_comma_regex =  /^[0-9,]*$/;//regex for numeric string
export const description_display_length = 30; // tree -> group headers / defines when to break the description to a short description

export const user_roles = [
    { key: 1, value: "Admin" },
    { key: 2, value: "Manager" },
    { key: 3, value: "Nutzer" }
];


export const tree_operators = [
    { key: "AND", value: "Innerhalb"},
    // { key: "GT", value: "Größer als" },
    { key: "GE", value: "Größer gleich" },
    { key: "LE", value: "Kleiner oder Gleich" },
    { key: "EQ", value: "Gleich" }
];
export const one_time_use_operator = ["GE", "LE", "GT", "LT"]; // tree -> operators which can only be used once per attribute


export const excluded_consultant_emails = [];

export const electricityVoltageLevel = [
    { value: "Niederspannung (Standard)", key: "0" },
    { value: "Mittelspannung", key: "1" },
    { value: "Hochspannung", key: "2" },
    { value: "Höchstspannung", key: "3" }
];

export const gasVoltageLevel = [
    { value: "Niederdruck (Standard)", key: "0" },
    { value: "Mitteldruck", key: "1" },
    { value: "Hochdruck", key: "2" }
];


export const cancellation_reasons = [
    { value: "Kalkulation", key: "calculation" },
    { value: "Keine Bonität", key: "missing_solvency" },
    { value: "Abweichende Bonität", key: "bad_solvency" },
    { value: "Abweichende Branche", key: "business_type" },
    { value: "Sonstiges", key: "else" }
];

export const sign_type_select = [ //consultant_authority
    {key: 'online', value: 'Der Vertrag wird mit einer online Signatur unterzeichnet. (Bald verfügbar)', disabled: true},
    {key: 'offline', value: 'Der Vertrag wird offline unterzeichnet. '},
];


export const contract_stati_selectable = [
    { value: "Annehmen", key: "accept" },
    { value: "Ablehnen", key: "revoke" },
    { value: "Widerrufen", key: "recall" }
];


export const sign_type_filter_options = [
    { value: "Digital", key: "ONLINE", headline: "Vertragstyp"},
    { value: "Offline", key: "OFFLINE" },
    { value: "Offline + E-Signatur", key: "E_SIGNATURE" }
];


export const price_guarantee_type_options = [
    { value: "Energiepreisgarantie", key: "energy_price", headline: "Preisgarantie"},
    { value: "Vollständige Preisgarantie", key: "contract_price"},
    { value: "Eingeschränkte Preisgarantie", key: "limited" },
    { value: "Keine Preisgarantie", key: "none"}
];

export const cancellation_period_options = [
    {key: 'none', value: 'Festes Vertragsende'},
    {key: '2 weeks', value: '2 Wochen'},
    {key: '1 month', value: '1 Monat'},
    {key: '3 months', value: '3 Monate'}
];

export const empty_select_value = '0'; // KZ / 02022022 / this global is not used everywhere yet where it should be (hardcoded '0' is still in some places in code)

// export const counter_status_options = [{key: 'relocation' , value: 'Neueinzug'}, // todo jetzt habitation status
//     {key: 'default_supplier', value: 'Grundversorgung'},
//     {key: 'continued_delivery', value: 'Folgebelieferung'},
//     {key: 'supplier_change', value: 'Lieferantenwechsel'}];

export const gender_options = [{key: 'male', value: 'Herr'},
    {key: 'female', value: 'Frau'},
    {key: 'other', value: 'Divers'}];

export const private_profiles = {H0: 'Haushalt'};

export const rlmProfiles = {
    ELECTRICITY: {
        R0: "Büro",
        R1: "Tankstelle",
        R2: "Metzgerei",
        R3: "Einzelhandel",
        R4: "Gastronomie",
        R5: "Bäckerei",
        R6: "Fitnessstudio",
        R7: "Handwerksberiebe",
        R8: "Wäscherei",
        R9: "Autohaus",
        R10: "Hotel",
        R11: "Produzierendes Gewerbe",
        R12: "Druckerei",
        R13: "Spedition",
        R14: "Sonstiges"
    },
    GAS: {
        "1D3/1D4": "Einfamilienhaushalte",
        "2D3/2D4": "Wohnungswirtschaft & Mehrfamilienhaushalte",
        // HK3: "Kochgas", TODO Falls wir umsteigen auf sigmoid wieder einkommentieren
        'AW3/AW4': "Wäschereien",
        'BG3/BG4': "Gartenbau",
        'DP3/DP4': "Papier & Druck",
        "OK3/OK4": "Gebietskörperschaften",
        "AH3/AH4": "Einzel- & Großhandel",
        "KM3/KM4": "Metallbau & KfZ",
        "HB3/HB4": "Beherbergungen",
        "AG3/AG4": "Gaststätten",
        "AB3/AB4": "Bäckereien",
        'FM3/FM4': "Haushaltähnliches Gewerbe"
    }
};

export const slpProfiles = {
    ELECTRICITY: {
        G0: "Sonstige",
        G1: "Gewerbe werktags 8-18 Uhr",
        G2: "Gewerbe mit starkem bis überwiegendem Verbrauch in den Abendstunden",
        G3: "Gewerbe durchlaufend",
        G4: "Ladenlokal, Friseur",
        G5: "Bäckerei mit Backstube",
        G6: "Wochenendbetrieb",
        L0: "Landwirtschaft allgemein",
        L1: "Landwirtschaftsbetriebe mit Milchwirtschaft/Nebenerwerbs-Tierzucht",
        L2: "Übrige Landwirtschaftsbetriebe"
    },
    GAS: {
        "1D3/1D4": "Einfamilienhaushalte",
        "2D3/2D4": "Wohnungswirtschaft & Mehrfamilienhaushalte",
        // HK3: "Kochgas", TODO Falls wir umsteigen auf sigmoid wieder einkommentieren
        'AW3/AW4': "Wäschereien",
        'BG3/BG4': "Gartenbau",
        'DP3/DP4': "Papier & Druck",
        "OK3/OK4": "Gebietskörperschaften",
        "AH3/AH4": "Einzel- & Großhandel",
        "KM3/KM4": "Metallbau & KfZ",
        "HB3/HB4": "Beherbergungen",
        "AG3/AG4": "Gaststätten",
        "AB3/AB4": "Bäckereien",
        'FM3/FM4': "Haushaltähnliches Gewerbe",
        "DB3/DB4": "Summenlastprofil (Sonstige)"
    }
};

/* translation for netFee Service */
export const arrayWithElectricityTranslations = {
    accounting_rate: "Abrechnungskosten",
    concession_fee: "Konzessionsabgabe",
    deactivation_levy: "§18 AbLaV Umlage",
    fee_kwk: "Abgabe KWK",
    fee_meter_reading: "Messkosten",
    fee_remote_reading: "Entgelt für Fernauslesung",
    fee_total: "Gesamtpreis",
    offshore_levy: "Offshore-Haftungsumlage",
    peak: "Leistungspreis",
    reactive_consumption: "Kosten für Blindmehrarbeit",
    real_energy_d: "Arbeitspreis",
    real_energy_n: "Arbeitspreis NT",
    renewable_energy_levy: "EEG-Umlage",
    socket: "Grundpreis",
    special_customer_additive: "§19 StromNEV Umlage",
    tax: "Stromsteuer"
};

export const arrayWithGasTranslations = {
    accounting_rate: "Abrechnungskosten",
    concession_fee: "Konzessionsabgabe",
    control_area: "Marktgebiet",
    neutrality_charge: "Bilanzierungsumlage",
    fee_measurement_reading: "Messkosten",
    fee_install_operate: "Wartung Messtechnik",
    //fee_remote_reading: 'Entgelt für Fernauslesung',
    fee_total: "Gesamtpreis",
    gas_quality: "Gasqualität",
    mean_consumption: "Verbrauch",
    meter_type: 0,
    peak: "Leistungspreis",
    // peak_fix: 0,
    // peak_given: 0,
    real_energy: "Arbeitspreis",
    // real_energy_fix: 0,
    socket: "Grundpreis",
    tax: "Erdgassteuer",
    co2_tax: "C02 Steuer"
};
/* end translation for netfee service */

export const live_urls = {
    CONSULTANT_PROJECT:  "https://login.energie-tarifrechner.de",
    EVU_PROJECT: "https://login.eless.de",
    WATTLINE_PROJECT: "https://cockpit.wattline.com"
};
export const dev_urls = {
    CONSULTANT_PROJECT: "https://dev.energie-tarifrechner.de",
    EVU_PROJECT: "https://dev-login.eless.de",
    WATTLINE_PROJECT: "https://dev-wattline-a.b2b-energievertrieb.de"
};
export const stage_urls = {
    CONSULTANT_PROJECT: "https://stage.energie-tarifrechner.de",
    EVU_PROJECT: "https://stage-login.eless.de",
    WATTLINE_PROJECT: "https://stage-wattline-a.b2b-energievertrieb.de"
};

export const tarif_optimum_url = "https://tarifoptimum.energie-tarifrechner.de";
export const teag_optimum_url = "https://teag.energie-tarifrechner.de";
export const local_url = "http://localhost:3000";

// offset warning loadprofile upload
export const consumption_offset = 0.1;

//loadProfile case
export const apiAccept = "application/json";
export const apiContentTypeFile = "multipart/form-data";

/* API Services */
export const apiWattlineService = "WattlineService";
export const apiInformationManagementService = "InformationManagementService";

export const apiFunctionAddComparison = "add_comparison_result";
export const apiFunctionRemoveComparison = "remove_comparison_result";


/* customer */
export const apiFunctionCreateCustomer = "create_customer";
export const apiFunctionUpdateCustomer = "modify_customer";
export const apiFunctionListCustomer = "list_customer";
export const apiFunctionGetConsumptionPointsByCustomer = "list_consumption_points";
export const apiFunctionGetConsumptionPointsByOffer = "get_consumption_points_of_offer";
export const apiFunctionCustomerFileAdd = "customer_file_add";
export const apiFunctionValidateCustomerInformation  = "validate_customer_information ";
export const apiFunctionCustomerFileList = "customer_file_list";


/* BANK ACCOUNT */
export const apiFunctionCreateBankAccount = "create_bank_account";
export const apiFunctionGetBankAccount = "get_bank_account";
export const apiFunctionUpadteBankAccount = "update_bank_account";


//CHARGEBEE
export const apiFunctionCreatePortalSession = "create_portal_session";


export const apiFunctionGetContractModify = "modify_contract";


export const apiFunctionListPartners = "list_partners";
export const apiFunctionUpdatePartners = "update_partners";


// PLATTFORM
export const apiFunctionUpdatePlattformSettings = "update_platform_settings";
export const apiFunctionGetPlattformSettings = "get_platform_settings";

/* consultant */
export const apiFunctionLoginConsultant = 'login_consultant';
export const apiFunctionModifyConsultant = 'modify_consultant';
export const apiFunctiongetGetConsultant = "get_consultant";

export const apiFunctionPredictionUpdate = "update_prediction";

// consumptionPoint functions
export const apiFunctionGetConsumptionPoint = "get_consumption_point";
export const apiFunctionListConsumptionPoint = "list_consumption_points";
export const apiFunctionSaveCheckout = "save_checkout";
export const apiFunctionCreateMemo = "create_memo";
export const apiFunctionUpdateMemo = "update_memo";
export const apiFunctionGetMemo = "get_memo";
export const apiFunctionCreateComparison = "create_comparison";

/* OFFER, TOKEN AND VOUCHER */
export const apiFunctionGetOffer = "get_offer";
export const apiFunctionListCheckout = "list_checkout";
export const apiFunctionDeleteMemo = "delete_memo";

/* WATTLINE */
export const apiFunctionGetMandate = "get_mandate";
export const apiFunctionGetInfo = "get_information";
export const apiFunctionReceiveLoadProfile = "receive_load_profile";







/* URLS */
export const rootUrl = '/';
export const Index = {
    url: '/lastgang-hochladen'
};
export const Teams = {
    url: "/teams"
};
export const TeamView = {
    url: "/team"
};
export const Bundling = {
    url: "/buendelung",
    team: "Vertrieb",
    side_bar: "energy_purchase",
    project: ["wattline, evu"]
};
export const Global = "/global";
export const Electricity = "/strom";
export const Gas = "/gas";
export const Customers = "/kunden";
export const CalculationUnits = "/kalkulationseinheiten";
export const CalculationUnitPath = "/kalkulationseinheit";
export const Tasks = "/aufgaben";
export const MyTasks = "/meine-aufgaben";
export const Task = "/aufgabe";
export const CustomerView = "/kunde";
export const PermissionDeniedPage = "/zugriff-verweigert";


export const rlmMinConsumption = {
    ELECTRICITY: 35000,
    GAS: 500000
};

export const slpMaxConsumption = {
    ELECTRICITY: 150000,
    GAS: 1500000
};

export const payment_packages = [
    "standard",
    "plus",
    "premium"
];



export const user_roles_manager = [
    { key: 2, value: "Manager" },
    { key: 3, value: "Nutzer" }
];

export const user_roles_user = [
    {key: 3, value: "Nutzer"}
];

export const user_roles_display = [
    { key: 1, value: "Admin" },
    { key: 2, value: "Manager"},
    { key: 3, value: "Nutzer" }
];


export const loadProfileOptionalMaxConsumption = 500000;

export const lowGasMaxCommission = 120;
export const mediumGasMaxCommission = 1.5;
export const highGasMaxCommission = 1;
export const endlessGasMaxCommission = 0.7;

export const lowElectricityMaxCommission = 120;
export const mediumElectricityMaxCommission = 1.5;
export const highElectricityMaxCommission = 1;
export const endlessElectricityMaxCommission = 1;


export const bindings = [
    {key: '1', value: '1 Stunde'},
    {key: '2', value: '2 Stunden'},
    {key: '4', value: '4 Stunden'},
    {key: '8', value: '8 Stunden'},
    {key: '24', value: '24 Stunden'},
    {key: '48', value: '48 Stunden'},
];


export const bindings_wattline = [
    {key: '1', value: '1 Stunde'},
    {key: '2', value: '2 Stunden'},
    {key: '4', value: '4 Stunden'},
    {key: '8', value: '8 Stunden'},
    {key: '24', value: '24 Stunden'},
];


export const offer_duration_options = [
    {value: '12', label: '12 Monate'},
    {value: '24', label: '24 Monate'},
    {value: '36', label: '36 Monate'},
];

export const examination_time_options = [
    {key: '1', value: '1 Stunde'},
    {key: '2', value: '2 Stunden'},
    {key: '4', value: '4 Stunden'},
    {key: '8', value: '8 Stunden'},
];

export const selectMonthOptions = [
    {key: 1, value: 'Januar'},
    {key: 2, value: 'Februar'},
    {key: 3, value: 'März'},
    {key: 4, value: 'April'},
    {key: 5, value: 'Mai'},
    {key: 6, value: 'Juni'},
    {key: 7, value: 'Juli'},
    {key: 8, value: 'August'},
    {key: 9, value: 'September'},
    {key: 10, value: 'Oktober'},
    {key: 11, value: 'November'},
    {key: 12, value: 'Dezember'},
];

export const shortMonthTranslations = [
    {eng: 'Mar', ger: 'Mär'},
    {eng: 'May', ger: 'Mai'},
    {eng: 'Oct', ger: 'Okt'},
    {eng: 'Dec', ger: 'Dez'},
];

export const cancellation_options = [
    {key: "none", value: 'Festes Vertragsende'},
    {key: "2 weeks", value: '2 Wochen'},
    {key: "1 month", value: '1 Monat'},
    {key: "3 months", value: '3 Monate'}
];

export const contract_extension_options = [
    {key: "none", value: 'Keine Vertragsverlängerung', headline: "Autom. Verlängerung"},
    {key: "1 month", value: '1 Monat'},
    {key: "3 months", value: '3 Monate'},
    {key: "6 months", value: '6 Monate'},
    {key: "12 months", value: '12 Monate'}
];
export const payment_method_options = [
    {value: 'Überweisung', key: 'credit_transfer'}, //was named payment_bank_transfer before
    {value: 'SEPA-Lastschrift', key: 'direct_debit'},
    {value: 'Barzahlung', key: 'payment_cash'},
];
export const habitation_status_options = [
    {key: 'relocation' , value: 'Neueinzug'},
    {key: 'default_supplier', value: 'Grundversorgung'},
    {key: 'continued_delivery', value: 'Folgebelieferung'},
    {key: 'supplier_change', value: 'Lieferantenwechsel'}
];
export const switching_date_status = [
    {key: 'successful' , value: 'Bestätigen'},
    {key: 'error', value: 'Stornieren'},
];


export const german_states_translation = {
    BB: "Brandenburg",
    BE: "Berlin",
    BW: "Baden-Wüttemberg",
    BY: "Bayern",
    HB: "Bremen",
    HE: "Hessen",
    HH: "Hamburg",
    MV: "Mecklenburg-Vorpommern",
    NI: "Niedersachsen",
    NW: "Nordrhein-Westfalen",
    RP: "Rheinland-Pfalz",
    SH: "Schleswig-Holstein",
    SL: "Saarland",
    SN: "Sachsen",
    ST: "Sachsen-Anhalt",
    TH: "Thüringen"
};

export const quantity_tolerance_filter_options = [
    {key: "5", value: 'Über +/- 5%', headline: "Mehr / Mindermengentoleranz", filter_option: [{name: "accepts_electronic_signature", value: "YES"}]},
    {key: "10", value: 'Über +/- 10%'},
    {key: "15", value: 'Über +/- 15%'},
    {key: "0", value: 'Unbegrenzt'},
];


export const energy_source_filter_options = [
    {key: "green", value: 'Öko', headline: "Energieherkunft"},
    // {key: "grey", value: 'Graustrom'},
];


export const tariff_filter_options = {
    contract_extension: contract_extension_options,
    quantity_tolerance: quantity_tolerance_filter_options,
    energy_source: energy_source_filter_options,
    sign_type_filter_value: sign_type_filter_options,
    price_guarantee_type: price_guarantee_type_options,
};

export const apiSolvencyService = "SolvencyService";



export const test_supplier = [
    1,  //eless
    113, //audax
    115, //badenova
    117, //e.optimum
    119, //eisenacher
    128, //energiedienst
    129, //gera
    133, // interconnector (enbw)
    138, // Amberg
    143, // Tübingen
    148, // vattenfall
    150, // VWS
    151, // Westfalen
    1303, // tender
    1599, // voltego
    1717, //eless
    2212, // voltego
    2485, // eless api test
];



export const arrayWithElectricityTranslationsTest = {
    SLP: {
        concession_fee: "Konzessionsabgabe",
        deactivation_levy: "AbLaV-Umlage §18 ",
        fee_kwk: "Abgabe KWK",
        fee_meter_reading: "Messstellenbetrieb",
        offshore_levy: "Offshore-Haftungsumlage",
        real_energy_d: "Arbeitspreis",
        renewable_energy_levy: "EEG-Umlage",
        fee_measurement_reading: "Messdienstleistungen",
        socket: "Grundpreis",
        special_customer_additive: "StromNEV-Umlage §19",
        tax: "Stromsteuer"
    },
    RLM: {
        peak: "Leistungspreis", //Leistungspreis
        concession_fee: "Konzessionsabgabe",
        deactivation_levy: "AbLaV-Umlage §18 ",
        fee_kwk: "Abgabe KWK",
        fee_meter_reading: "Messstellenbetrieb",
        fee_measurement_reading: "Messdienstleistungen",
        offshore_levy: "Offshore-Haftungsumlage",
        real_energy_d: "Arbeitspreis",
        renewable_energy_levy: "EEG-Umlage",
        socket: "Grundpreis",
        special_customer_additive: "StromNEV-Umlage §19",
        tax: "Stromsteuer",
    }
};

export const arrayWithGasTranslationsTest = {
    SLP: {
        accounting_rate: "Abrechnung",
        fee_meter_reading: "Messdienstleistungen",
        fee_measurement_reading: "Messstellenbetrieb",
        socket : "Grundpreis",
        concession_fee : "Konzessionsabgabe",
        real_energy: "Arbeitspreis",
        tax: "Erdgassteuer",
        neutrality_charge: "Bilanzierungsumlage",
        co2_tax: "CO2 Steuer",
        control_area: "Marktgebiet",
        mean_consumption: "Jahresverbrauch",
        gas_quality : "Gasqualität",
    },
    RLM: {
        accounting_rate: "Abrechnung",
        fee_meter_reading: "Messstellenbetrieb",
        fee_measurement_reading: "Messdienstleistungen",
        socket : "Grundpreis",
        concession_fee : "Konzessionsabgabe",
        peak : "Leistungspreis", // nicht bei SLP
        real_energy: "Arbeitspreis",
        tax: "Erdgassteuer",
        neutrality_charge: "Bilanzierungsumlage",
        co2_tax: "CO2 Steuer",
        control_area: "Marktgebiet",
        mean_consumption: "Jahresverbrauch",
        gas_quality : "Gasqualität",
        // peak_load: "Lastspitze",
    }
};


export const gasNetfeeNotDisplay = [
    "control_area", "gas_quality", "mean_consumption"
];

export const gasNetfeeBasicInfo = [
    "control_area", "gas_quality"
];

export const electricityNetfeeNotDisplay = [
    "mean_consumption"
];

export const max_file_size = 5000;






















